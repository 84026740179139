<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">Payment Type</h1>
    </div>
    <div class="title-tabs mt-3">Payment Type</div>
    <b-container class="no-gutters bg-white">
      <div class="pt-3">
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Name"
              placeholder="Name"
              type="text"
              name="branch_name_en"
              v-model="form.name"
              isRequired
              :v="$v.form.name"
              :isValidate="$v.form.name.$error"
            />
          </b-col>

          <b-col lg="6" md="6" sm="12" xs="12">
            <InputText
              textFloat="Sort Order"
              placeholder="Sort Order"
              type="number"
              name="sort_order"
              v-model="form.sort_order"
            />
          </b-col>
        </b-row>
      </div>
    </b-container>

    <FooterAction routePath="/setting/payment-type" @submit="saveForm()" />
  </div>
</template>
<script>
import { required, requiredIf } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      form: {
        id: "0",
        name: "",
        status: 1,
        sort_order: 0,
      },
      paymentId: this.$route.params.id || 0,
    };
  },
  validations: {
    form: {
      name: { required },
    },
  },
  created() {
    if (this.paymentId != 0) {
      this.getList();
    }
  },

  methods: {
    async getList() {
      this.$EventBus.$emit("showLoading");
      const res = await this.$Axios(
        `${process.env.VUE_APP_API}/Setting/GetPayment/${this.paymentId}`,
        this.filter
      );
      this.form = res.data.detail;
      this.$EventBus.$emit("hideLoading");
    },
    async saveForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      let url = "";
      var res = {};
      if (this.form.sort_order == "") {
        this.form.sort_order = 0;
      }
      this.$EventBus.$emit("showLoading");
      if (this.paymentId == 0) {
        res = await this.$Axios.post(
          `${process.env.VUE_APP_API}/Setting/CreatePayment`,
          this.form
        );
      } else {
        res = await this.$Axios.put(
          `${process.env.VUE_APP_API}/Setting/UpdatePayment`,
          this.form
        );
      }
      this.$EventBus.$emit("hideLoading");
      if (res.data.result == 1) {
        this.successAlert().then(() => {
          this.$router.push("/setting/payment-type");
        });
      } else this.errorAlert(res.data.message);
    },
  },
};
</script>
<style lang="scss">
.icon-primary {
  color: var(--primary-color);
}
</style>
